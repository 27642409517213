// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.node {
    cursor: pointer;
    stroke: #555;
    stroke-width: 0.5px;
    outline: none;
}

.node:hover {
    stroke: #000;
    stroke-width: 2.0px;
}

.node--leaf {
    fill: white;
    pointer-events: all;
}

.label,
.node--root,
.node--leaf {
    pointer-events: all;
}`, "",{"version":3,"sources":["webpack://./bubble-panel.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,mBAAmB;AACvB;;AAEA;;;IAGI,mBAAmB;AACvB","sourcesContent":[".node {\n    cursor: pointer;\n    stroke: #555;\n    stroke-width: 0.5px;\n    outline: none;\n}\n\n.node:hover {\n    stroke: #000;\n    stroke-width: 2.0px;\n}\n\n.node--leaf {\n    fill: white;\n    pointer-events: all;\n}\n\n.label,\n.node--root,\n.node--leaf {\n    pointer-events: all;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
